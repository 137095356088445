import * as React from "react";
import "bootstrap/dist/css/bootstrap.min.css";

// markup
const SiteFooter = ({ pageTitle, children }) => {
  return (
    <div className="text-center p-6 bg-gray-200">
      <span>© 2022 Copyright</span>
    </div>
  );
};

export default SiteFooter;
