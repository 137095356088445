import * as React from "react";
import ContactForm from "./stateful/contact";
// import { Col, Row } from "react-bootstrap";
// import * as bs from "../styles/base.module.css";
// const styles = {
//   '@global': {
//     body: {
//       color: 'green'
//     },
//     a: {
//       textDecoration: 'underline'
//     }
//   },

// import * as bs from "../styles/base.css";
// const contentStyle = {
//   backgroundColor: "#f45",
//   padding: 96,
//   fontFamily: "-apple-system, Roboto, sans-serif, serif",
// };
// const color1 = {
//   display: "flex",
//   backgroundColor: "#cc5511",
//   height: "100%",
// };

// const color2 = {
//   backgroundColor: "#ff22aa",
// };
// const contentRow = {
//   height: 600,
//   textAlign: "center",
// };

const Content = () => {
  return (
    <div class="flex flex-col pt-64 items-center justify-center">
      <div class="p-3 m-2 font-lehigh ">
        <h1 class="font-vibur text-[#e2f9dc] text-[6vw] text-shadow">
          Green Market Cannabis
        </h1>
        <h2 class="text-3l text-center">Professional Cannabis Consultants</h2>
        <ContactForm></ContactForm>
      </div>
      {/* <div class="flex p-3 m-2 w-1/2 aspect-square items-center justify-center font-lehigh ">
        <div class="flex flex-col w-full">
          <ContactForm></ContactForm>
        </div>
      </div> */}
    </div>
  );
};

export default Content;

// class NameForm extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = {value: ''};
//     this.handleChange = this.handleChange.bind(this);
//     this.handleSubmit = this.handleSubmit.bind(this);
//   }

//   handleChange(event) {    this.setState({value: event.target.value});  }
//   handleSubmit(event) {
//     alert('A name was submitted: ' + this.state.value);
//     event.preventDefault();
//   }

//   render() {
//     return (
//       <form onSubmit={this.handleSubmit}>        <label>
//           Name:
//           <input type="text" value={this.state.value} onChange={this.handleChange} />        </label>
//         <input type="submit" value="Submit" />
//       </form>
//     );
//   }
// }
