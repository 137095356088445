import * as React from "react";
// import Container from "react-bootstrap/Container";

// import * as baseStyles from "../styles/base.module.css";
import "../styles/global.css";
import SiteNavbar from "./navbar";
import SiteFooter from "./footer";

// import * as bs from "../styles/base.module.css";
import "bootstrap/dist/css/bootstrap.min.css";

// Layout contains any repeated elements like headers and footers
const SiteWrapper = ({ pageTitle, children }) => {
  return (
    <div className="h-screen flex flex-col">
      {/* <SiteNavbar></SiteNavbar> */}
      {/* Sidth controller is to place main content */}
      {/* <div className="container h-full mx-auto px-4">{children}</div> */}
      <div className="container h-full">{children}</div>
      <SiteFooter></SiteFooter>
    </div>
  );
};
export default SiteWrapper;
