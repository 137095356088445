import React from "react";

class ContactForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = { value: "" };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({ value: event.target.value });
  }
  handleSubmit(event) {
    alert("A name was submitted: " + this.state.value);
    event.preventDefault();
  }

  render() {
    return (
      <div className="flex items-center justify-center py-4 px-4 sm:px-6 lg:px-8">
        <div className="w-4/5 space-y-4">
          <form className="space-y-4" onSubmit={this.handleSubmit}>
            <div className="rounded-md shadow-sm -space-y-px">
              <div>
                <input
                  id="contact-name"
                  name="Name"
                  type="text"
                  autoComplete="name"
                  required
                  className="appearance-none rounded-none relative block
                    w-full px-3 py-2 border border-gray-300
                    placeholder-gray-500 text-gray-900 rounded-t-md
                    focus:outline-none focus:ring-lime-500
                    focus:border-lime-500 focus:z-10 sm:text-sm"
                  placeholder="Name/Business"
                />
              </div>
              <div>
                <input
                  id="contact-email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  className="appearance-none rounded-none relative block
                    w-full px-3 py-2 border border-gray-300
                    placeholder-gray-500 text-gray-900 rounded-b-md
                    focus:outline-none focus:ring-lime-500
                    focus:border-lime-500 focus:z-10 sm:text-sm"
                  placeholder="you@email.com"
                />
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="group relative w-full flex justify-center
                  py-2 px-4 border border-transparent text-sm font-medium
                  rounded-md text-white bg-lime-600 hover:bg-lime-700
                  focus:outline-none focus:ring-2 focus:ring-offset-2
                  focus:ring-lime-500"
              >
                {/* <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                  <LockClosedIcon
                    className="h-5 w-5 text-lime-500 group-hover:text-lime-400"
                    aria-hidden="true"
                  />
                </span> */}
                Say hello
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}
export default ContactForm;

// render() {
//   return (
//     <form class="flex" onSubmit={this.handleSubmit}>
//       <label class="flex-1 w-64">Name:</label>
//       <input type="text" class="flex-1 w-64" />
//       {/* <input
//           type="text"
//           value={this.state.value}
//           onChange={this.handleChange}
//         />{" "} */}

//       <label>
//         Email:
//         <input type="email" class="form-input px-1 " />
//       </label>
//       <input type="submit" value="Submit" />
//     </form>
//   );
// }
