import * as React from "react";
import SiteWrapper from "../components/site-wrapper";
import Content from "../components/content";

// footer solve: https://play.tailwindcss.com/ySZ2dVstut

const IndexPage = () => {
  return (
    <SiteWrapper>
      <Content></Content>
    </SiteWrapper>
  );
};

export default IndexPage;
